import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import logo from '@/assets/Logo2.jpg';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const genderOptions = [
  { value: "", text: "Seçin", disabled: true },
  {
    text: "Erkek",
    value: "male",
  },
  {
    text: "Kadın",
    value: "female",
  },
]


const roleOptions = [
  { text: "Seçin", value: "" },
  {text: "Ferdi Sporcu", value: "individual"},
  { text: "Antrenör", value: "trainerState" },
  { text: "Hakem", value: "sportRefereeState" },
  { text: "İl Temsilcisi", value: "provincialRepresentative" },
  { text: "Kurul Üyesi", value: "assemblyMember" },
  { text: "Personel", value: "personel" },
  { text: "Sporcu", value: "sportState" },
  { text: "Yönetici", value: "manager" },
];


const generateHTML = async (title, fields, items, pageOrientation) => {
  var docDefinition = {
    content: [
      {
        alignment: 'justify',
        style: 'margin_bottom',
        columns: [
          {
            image: await getBase64ImageFromURL(logo),
            width: 48,
            height: 48
          },
          {

            text: title,
            style: "header"
          },
          {
            image: await getBase64ImageFromURL(logo),
            width: 48,
            height: 48
          },
        ],
      },
      {
        columns: [
          { width: '*', text: '' },
          {
            style: "tableStyle",
            width: "auto",
            table: {
              alignment: 'center',
              body: [
                fields.map((e) => { return { text: e, style: "title" } }),
                ...items
              ]
            }
          },
          { width: '*', text: '' },
        ]
      },
    ],
    pageOrientation: pageOrientation ? "landscape" : "portrait",
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 15, 0, 0],
        alignment: 'center',
      },
      title: {
        bold: true,

      },
      margin_bottom: {
        margin: [0, 0, 0, 40]
      },
      tableStyle: {
        fontSize: 7,
        padding: [0, 0, 0, 0]
      }
    }
  };
  pdfMake.createPdf(docDefinition).download();
}



const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}

const getPdfItemsFromServer = async (endpoint, params) => {
  return (await PMSAxiosHelper
    .post("/api/reports/" + endpoint, params)).result

}


const getSelectedPersonelColumns = (includeTitles, form) => {
  let personel = {
    personel: [],
    education: [],
    branch: [],
    system: [],
    contact: [],
  };
  personel.personel = form.personel.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });
  personel.branch = form.branch.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });
  personel.education = form.education.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });
  personel.contact = form.contact.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });
  personel.system = form.system.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });

  return personel;
}

const getSelectedClubColumns = (includeTitles, form) => {
  let club = {
    club: [],

  };
  club.club = form.club.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });

  return club;
}

const getSelectedCompetitionColumns = (includeTitles, form) => {
  let competitions = {
    competitions: [],

  };
  competitions.competitions = form.competitions.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });

  return competitions;
}
const getSelectedCompetitionGroupColumns = (includeTitles, form) => {
  let competitionGroups = {
    competitions: [],

  };
  competitionGroups.competitionGroups = form.competitionGroups.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });

  return competitionGroups;
}

const getSelectedMatchColumns = (includeTitles, form) => {
  let matches = {
    matches: [],

  };
  matches.matches = form.matches.columns
    .filter((e) => e.selected || e.filter)
    .map((e) => {
      return {
        selected: e.selected,
        columnName: e.columnName,
        title: includeTitles ? e.title : undefined,
        filter: e.filter,
        advancedFilter:
          e.filter && e.advancedFilter ? e.advancedFilter.value : undefined,
        advancedFilterInput:
          e.filter && e.advancedFilter ? e.advancedFilter.input : undefined,
        filterOptions:
          e.filter && e.filterOptions ? e.filterOptions.value : undefined,
      };
    });

  return matches;
}


const downloadSinglePersonelReport = async (request) => {

  var docDefinition = {
    content: [
      {
        alignment: 'justify',
        style: 'margin_bottom',
        columns: [
          {
            image: await getBase64ImageFromURL(logo),
            width: 48,
            height: 48
          },
          {

            text: "Türkiye Kano Federasyonu - Personel Dökümü",
            style: "header"
          },
          {
            image: await getBase64ImageFromURL(logo),
            width: 48,
            height: 48
          },
        ],
      },
      Object.keys(request.personelInformation).length > 0 ? [{

        columns: [
          {
            text: "Kişisel Bilgiler",
            style: "title"
          },

        ]
      },
      {
        style: 'tableExample',
        table: {
          body: [
            ...Object.keys(request.personelInformation).length != 0 ? [
              [{ text: 'Ad', style: 'titleRightPadding' }, { text: request.personelInformation.name }],
              [{ text: 'Soyad', style: 'titleRightPadding' }, { text: request.personelInformation.surname }],
              [{ text: 'T.C. Kimlik Numarası', style: 'titleRightPadding' }, { text: request.personelInformation.ssn }],
              [{ text: 'Cinsiyet', style: 'titleRightPadding' }, { text: genderOptions.find((e) => e.value == request.personelInformation.gender).text }],
              [{ text: 'Doğum Tarihi', style: 'titleRightPadding' }, { text: request.personelInformation.birthDay }],

            ] : [],
          ]
        }
      },] : {},
      Object.keys(request.branchInformation).length > 0 ? [{

        columns: [
          {
            text: "Branş Bilgileri",
            style: "title"
          },

        ]
      },
      {
        style: 'tableExample',
        table: {
          body: [
            ...Object.keys(request.branchInformation).length != 0 ? [
              [{ text: 'Kulüp Adı', style: 'titleRightPadding' }, { text: request.branchInformation.clubName }],
              [{ text: 'Sporcu', style: 'titleRightPadding' }, { text: request.branchInformation.sportState == "active" ? "Faal" : "  Değil" }],
              [{ text: 'Spor Son Lisans Tarihi', style: 'titleRightPadding' }, { text: request.branchInformation.sportLastLicenseDate ? request.branchInformation.sportLastLicenseDate : "Yok" }],
              [{ text: 'Hakem', style: 'titleRightPadding' }, { text: request.branchInformation.sportRefereeState == "active" ? "Faal" : "Değil" }],
              [{ text: 'Hakem Son Lisans Tarihi', style: 'titleRightPadding' }, { text: request.branchInformation.sportRefereeLastLicenseDate ? request.branchInformation.sportRefereeLastLicenseDat : "Yok" }],
              [{ text: 'Antrenör', style: 'titleRightPadding' }, { text: request.branchInformation.trainerState == "active" ? "Faal" : "Değil" }],
              [{ text: 'Antrenör Son Lisans Tarihi', style: 'titleRightPadding' }, { text: request.branchInformation.trainerLastLicenseDate ? request.branchInformation.trainerLastLicenseDate : "Yok" }],

            ] : [],
          ]
        }
      },] : {},
      Object.keys(request.competitionGroupInformation).length > 0 ? [
        {
          columns: [
            {
              text: "Yarışma Bilgileri",
              style: "title_bottomPadding"
            },

          ]
        },


        ...getAllCompetitions(request.competitionGroupInformation)
      ] : {},

    ],
    pageOrientation: "portrait",
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 15, 0, 0],
        alignment: 'center',
      },
      title: {
        bold: true,

      },
      title_bottomPadding: {
        bold: true,
        margin: [0, 0, 0, 40]

      },
      titleRightPadding: {
        bold: true,
        margin: [10, 0, 0, 0]
      },
      margin_bottom: {
        margin: [0, 0, 0, 40]
      },
      margin_top: {
        margin: [0, 10, 0, 0]
      },
      tableStyle: {
        fontSize: 7,
        padding: [0, 0, 0, 0]
      },
      tableExample: {
        margin: [0, 10, 0, 20]
      }

    }
  };
  pdfMake.createPdf(docDefinition).download();
}

const getAllCompetitions = (docDefinition) => {
  //TODO: BU YAPIYI SAĞLAMAK İÇİN KULLANICININ YARIŞMALAR KISMINDAKİ GİBİ YAP
  const res = docDefinition.map((d) =>
    
    Object.keys(d).length > 0 ? [{
      unbreakable: true,
      columns: [
        {
          text: d.competition.name,
          style: "title"
        },

      ]
    },
    {
      unbreakable: true,
      style: 'tableExample',
      table: {
        body: [
          ...Object.keys(d).length != 0 ? [
            [{ text: 'Tarih Saat', style: 'titleRightPadding' }, {
              text: d.createdAt
                ? d.createdAt
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join(".") +
                " " +
                d.createdAt
                  .split("T")[1]
                  .split(".")[0]
                  .replace(":", ".")
                : ""
            }],
            [{ text: 'Katılım Şekli', style: 'titleRightPadding' }, { text:roleOptions.find(f => f.value == d.formOfParticipation) }],
            [{ text: 'Kategori', style: 'titleRightPadding' }, { text: d.categoryRel.title}],
            [{ text: 'Tekne Sınıfı', style: 'titleRightPadding' }, { text: d.boatTypeRel.title }],
            [{ text: 'Mesafe', style: 'titleRightPadding' }, { text:d.distanceRel.title }],
            [{ text: 'Derece', style: 'titleRightPadding' }, { text: d.degree }],
            [{ text: 'Ödül', style: 'titleRightPadding' }, { text: d.prize }],
            [{ text: 'Açıklama', style: 'titleRightPadding' }, { text: d.description }],

          ] : [],
        ]
      }
    },] : {}
  )
  return res
}

export default {
  generateHTML,
  getPdfItemsFromServer,
  getSelectedPersonelColumns,
  getSelectedClubColumns,
  getSelectedMatchColumns,
  getSelectedCompetitionGroupColumns,
  getSelectedCompetitionColumns,
  downloadSinglePersonelReport
}